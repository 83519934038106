import React from "react";
import BaseCard from "./BaseCard.js";
import { Link } from "react-router-dom"
import FadeInModal from "./FadeInModal";
import D3LinearChart from "./D3LinearChart.js";
import { CardTitle } from "reactstrap";
import ModalMetodology from "components/Modals/Metodology.js";

export default function GraphCard(props) {
    const { graphData, justDetailButton, institution } = props;
    const [modalState, setModalState] = React.useState(false);

    return (
        <>
            <BaseCard>
                <div className="graphCardHeader">
                    <Link to={"/viewChart/" + graphData.key} style={{ textDecoration: 'none' }}>
                        <CardTitle className="emphasizedHeader">{graphData.title}</CardTitle>
                    </Link>
                    {
                        <div className="graphOptionsMenuContainer">
                            <div className="graphOptionsMenuButton" onClick={() => { setModalState(true); }}>
                                <div className='graphOptionsMenuIcon'>
                                    <i class="fas fa-info fa-1x"></i>
                                </div>
                                <CardTitle variant="subtitle2">Metodología</CardTitle>
                            </div>
                        </div>
                    }
                </div>

                <div>
                    <Link to={"/viewChart/" + graphData.key} style={{ textDecoration: 'none' }}>
                        {
                            <div>
                                <div>
                                    <D3LinearChart width={600} height={355} source={graphData.data} />
                                </div>
                            </div>
                        }
                    </Link>
                </div>
            </BaseCard>
            <ModalMetodology title={graphData.title} desc={graphData.metodology} setShow={setModalState} show={modalState} />
        </>
    );
}